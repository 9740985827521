.slick-slider {
  .slick-list {
    width: 100%;
  }
  .slick-slide {
    outline: none;
  }
  .slick-track {
    margin: 0;
  }
}
.slick-arrow {
  position: absolute;
  right: 25px;
  top: 50%;
  background: none;
  outline: none;
  width: 20px;
  height: 20px;
  border: none;
  transform: translateY(-50%);
  cursor: pointer;
  @media screen and (min-width: 990px) {
    right: auto;
    left: 0;
  } 
  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-left: 2px solid $color-white;
    border-bottom: 2px solid $color-white;
    transform: rotate(45deg);
  }
}
.slick-next {
  left: auto;
  right: 0;
  @media screen and (min-width: 990px) {
    left: auto;
    right: 0;
  } 
  &::before {
    transform: rotate(-135deg);
  }
}
.slick-disabled {
  opacity: 0.3;
}