.select2-container--default {
  .select2-selection--single {
    background: none;
    border: none;
    .select2-selection__rendered {
      padding-left: 0;
    }
  }
  .select2-results__option {
    &--selected {
      background: #34353F;
    }
  }
}
.select2-selection {
  &__result {
    display: flex;
    align-items: center;
    color: $color-white;
    font: $bold 18px / 1 $font;
    @media screen and (min-width: 480px) {
      font-size: 20px;
    }
  }
  &__flag {
    flex: 0 0 auto;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 7px;
  }
}
.select2-dropdown {
  background: #1E1F2A;
  border: 0;
  overflow: hidden;
}