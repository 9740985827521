*,
:before,
:after {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body {
  font: $regular 17px / 1.5 $font;
  background: $color-black;
  color: $color-white;
  -webkit-font-smoothing: antialiased;
}
a {
  color: $color-white;
  outline: none;
  &:hover,
  &:active {
    text-decoration: none;
  }
}
p {
  margin: 0 0 27px;
}
img {
  display: block;
  max-width: 100%;
}
i,
em {
  font-style: italic;
}
strong,
b {
  font-weight: $bold;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: $light;
  color: $color-white;
  margin: 0 0 27px;
  line-height: normal;
}
h1,
.h1 {
  font-size: 117px;
}
h2,
.h2 {
  font-size: 96px;
}
h3,
.h3 {
  font-size: 56px;
}
h4,
.h4 {
  font-size: 48px;
}
h5,
.h5 {
  font-size: 45px;
}
h6,
.h6 {
  font-size: 35px;
}
ul,
ol {
  list-style: none;
  margin: 0 0 27px;
  padding: 0;
  &.marker {
    li {
      position: relative;
      padding-left: 20px;
    }
  }
}
ul.marker {
  li {
    &:before {
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      position: absolute;
      left: 9px;
      top: 11px;
      background: $color-white;
      border-radius: 50%;
    }
  }
}
ol.marker {
  li {
    counter-increment: step-counter;
    &:before {
      content: counter(step-counter) '.';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      color: $color-white;
      font-weight: $light;
    }
  }
}
q {
  font-style: italic;
  display: block;
  margin: 0 0 27px;
}
small {
  font-size: 80%;
}
svg {
  display: block;
  overflow: hidden;
}
input,
textarea {
  &::-webkit-input-placeholder {
    color: $color-white;
    opacity: 0.5;
  }
  &::-moz-placeholder {
    color: $color-white;
    opacity: 0.5;
  }
  &:-moz-placeholder {
    color: $color-white;
    opacity: 0.5;
  }
  &:-ms-input-placeholder {
    color: $color-white;
    opacity: 0.5;
  }
}
input::-ms-clear {
  display: none;
}
.clearfix:after {
  content: '';
  display: table;
  clear: both;
}
.cover {
  padding: 0 15px;
  margin: 0 auto;
  min-width: 320px;
  @media screen and (min-width: 600px) {
    max-width: 570px;
  }
  @media screen and (min-width: 768px) {
    max-width: 730px;
  }
  @media screen and (min-width: 990px) {
    max-width: 970px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 1170px;
  }
  @media screen and (min-width: 1360px) {
    max-width: 1284px;
  }
}



