@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}
*,
:before,
:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font: 400 17px/1.5 "Work Sans", sans-serif;
  background: #090A0E;
  color: #FFF;
  -webkit-font-smoothing: antialiased;
}

a {
  color: #FFF;
  outline: none;
}
a:hover, a:active {
  text-decoration: none;
}

p {
  margin: 0 0 27px;
}

img {
  display: block;
  max-width: 100%;
}

i,
em {
  font-style: italic;
}

strong,
b {
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 300;
  color: #FFF;
  margin: 0 0 27px;
  line-height: normal;
}

h1,
.h1 {
  font-size: 117px;
}

h2,
.h2 {
  font-size: 96px;
}

h3,
.h3 {
  font-size: 56px;
}

h4,
.h4 {
  font-size: 48px;
}

h5,
.h5 {
  font-size: 45px;
}

h6,
.h6 {
  font-size: 35px;
}

ul,
ol {
  list-style: none;
  margin: 0 0 27px;
  padding: 0;
}
ul.marker li,
ol.marker li {
  position: relative;
  padding-left: 20px;
}

ul.marker li:before {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  position: absolute;
  left: 9px;
  top: 11px;
  background: #FFF;
  border-radius: 50%;
}

ol.marker li {
  counter-increment: step-counter;
}
ol.marker li:before {
  content: counter(step-counter) ".";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  color: #FFF;
  font-weight: 300;
}

q {
  font-style: italic;
  display: block;
  margin: 0 0 27px;
}

small {
  font-size: 80%;
}

svg {
  display: block;
  overflow: hidden;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #FFF;
  opacity: 0.5;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #FFF;
  opacity: 0.5;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #FFF;
  opacity: 0.5;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #FFF;
  opacity: 0.5;
}

input::-ms-clear {
  display: none;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.cover {
  padding: 0 15px;
  margin: 0 auto;
  min-width: 320px;
}
@media screen and (min-width: 600px) {
  .cover {
    max-width: 570px;
  }
}
@media screen and (min-width: 768px) {
  .cover {
    max-width: 730px;
  }
}
@media screen and (min-width: 990px) {
  .cover {
    max-width: 970px;
  }
}
@media screen and (min-width: 1200px) {
  .cover {
    max-width: 1170px;
  }
}
@media screen and (min-width: 1360px) {
  .cover {
    max-width: 1284px;
  }
}

.slick-slider .slick-list {
  width: 100%;
}
.slick-slider .slick-slide {
  outline: none;
}
.slick-slider .slick-track {
  margin: 0;
}

.slick-arrow {
  position: absolute;
  right: 25px;
  top: 50%;
  background: none;
  outline: none;
  width: 20px;
  height: 20px;
  border: none;
  transform: translateY(-50%);
  cursor: pointer;
}
@media screen and (min-width: 990px) {
  .slick-arrow {
    right: auto;
    left: 0;
  }
}
.slick-arrow::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-left: 2px solid #FFF;
  border-bottom: 2px solid #FFF;
  transform: rotate(45deg);
}

.slick-next {
  left: auto;
  right: 0;
}
@media screen and (min-width: 990px) {
  .slick-next {
    left: auto;
    right: 0;
  }
}
.slick-next::before {
  transform: rotate(-135deg);
}

.slick-disabled {
  opacity: 0.3;
}

.select2-container--default .select2-selection--single {
  background: none;
  border: none;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
}
.select2-container--default .select2-results__option--selected {
  background: #34353F;
}

.select2-selection__result {
  display: flex;
  align-items: center;
  color: #FFF;
  font: 700 18px/1 "Work Sans", sans-serif;
}
@media screen and (min-width: 480px) {
  .select2-selection__result {
    font-size: 20px;
  }
}
.select2-selection__flag {
  flex: 0 0 auto;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 7px;
}

.select2-dropdown {
  background: #1E1F2A;
  border: 0;
  overflow: hidden;
}